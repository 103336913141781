import { Api } from "@/models/class/api.class";
import { Pagination } from "@/models/constant/interface/common.interface";
import {
  AmortizationDetailResponseDto,
  AmortizationRequestDto,
  AmortizationResponseDto,
  AmortizationTransactionTypeDto,
  CloseAmortizationDetailResponseDto,
  CloseAmortizationRequestDto,
} from "@/models/interface/amortization";
import { DownloadRequestParam } from "@/models/interface/download";
import {
  ErrorResponseModel,
  RequestQueryParamsModel,
} from "@/models/interface/http.interface";
import { AxiosError } from "axios";
import { HttpClient } from "./HttpClient.service";

class AmortizationService extends HttpClient {
  getTransactionTypes(): Promise<AmortizationTransactionTypeDto[]> {
    return this.get<AmortizationTransactionTypeDto[]>(
      Api.AmortizationTransactionTypes
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createTransactionTypes(
    payload: AmortizationTransactionTypeDto
  ): Promise<AmortizationTransactionTypeDto> {
    return this.post<
      AmortizationTransactionTypeDto,
      AmortizationTransactionTypeDto
    >(Api.AmortizationTransactionTypes, payload)
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getAmortizationDetail(id: string): Promise<AmortizationDetailResponseDto> {
    return this.get<AmortizationDetailResponseDto>(
      `${Api.AmortizationDetail}/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  closeAmortization(
    id: string,
    payload: CloseAmortizationRequestDto
  ): Promise<void> {
    return this.put<void, CloseAmortizationRequestDto>(
      `${Api.CloseAmortization}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getAmortizations(
    params?: RequestQueryParamsModel
  ): Promise<Pagination<AmortizationResponseDto>> {
    return this.get<Pagination<AmortizationResponseDto>>(Api.AmortizationList, {
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  downloadAmortizations(params: DownloadRequestParam): Promise<Blob> {
    return this.get<Blob>(Api.DownloadAmortizations, {
      responseType: "blob",
      params,
    })
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  createAmortization(
    payload: AmortizationRequestDto
  ): Promise<AmortizationDetailResponseDto> {
    return this.post<AmortizationDetailResponseDto, AmortizationRequestDto>(
      Api.Amortization,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  submitAmortization(
    payload: AmortizationRequestDto
  ): Promise<AmortizationDetailResponseDto> {
    return this.post<AmortizationDetailResponseDto, AmortizationRequestDto>(
      Api.AmortizationSubmit,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  updateAmortization(
    id: string,
    payload: AmortizationRequestDto
  ): Promise<AmortizationDetailResponseDto> {
    return this.put<AmortizationDetailResponseDto, AmortizationRequestDto>(
      `${Api.Amortization}/${id}`,
      payload
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  cancelAmortization(id: string): Promise<AmortizationDetailResponseDto> {
    return this.put<AmortizationDetailResponseDto>(
      `${Api.AmortizationCancel}/${id}`
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }

  getCloseAmortizationDetail(
    id: string,
    unitId: string
  ): Promise<CloseAmortizationDetailResponseDto> {
    return this.get<CloseAmortizationDetailResponseDto>(
      `${Api.CloseAmortizationDetail}/${id}`,
      {
        params: {
          amortizationUnitId: unitId,
        },
      }
    )
      .then(this.success)
      .catch((error: AxiosError<ErrorResponseModel>) => {
        throw this.error(error);
      });
  }
}

export const amortizationService = new AmortizationService();
