import { CloseAmortizationDetailLineResponseDto } from "./CloseAmortizationDetailLineResponseDto.interface";

export class CloseAmortizationDetailResponseDto {
  amortizationNumber = "";
  transactionType = "";
  closeDate = "";
  description = "";
  leasingId = "";
  leasingNumber = "";
  leasingContractNumber = "";
  closingNote = "";
  assetId = "";
  unitCode = "";
  lineResponseDTOS: CloseAmortizationDetailLineResponseDto[] = [];
  journalNumber = "";
  journalId = "";
}
